<template>
  <div class="container" style="padding: 10px">
    <div class="columns">
      <div class="column is-12">
        <img src="../../assets/logo.svg">
      </div>
    </div>
    <div class="columns is-centered">
      <a @click="$ml.change('ru')" title="Русский" style="margin-right: 5px">
        <img src="../../assets/ru.png">
      </a>
      <a @click="$ml.change('en')" title="English">
        <img src="../../assets/en.png">
      </a>
    </div>
    <div class="columns is-centered">
      <div class="column is-4">
        <form autocomplete="off" @submit.prevent="login" novalidate>
          <b-loading :is-full-page="false" v-model="formLoading" :can-cancel="false"></b-loading>
          <b-field label="Email" label-position="on-border"
                   :message="'email' in errors ? errors['email'] : ''"
                   :type="'email' in errors ? 'is-danger' : ''">
            <b-input autocomplete="nope" type="text" v-model="form.email" icon="email"></b-input>
            <p class="control">
              <b-button :disabled="!submitEnabled" type="is-info" native-type="submit">
                {{ $ml.get('reset_password') }}
              </b-button>
            </p>
          </b-field>
        </form>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-4">
        <ul class="horizontal">
          <li>
            <router-link :to="{ path: `/signup` }">{{ $ml.get('login_signup') }}</router-link>
          </li>
          <li style="padding: 0 10px">
            |
          </li>
          <li>
            <router-link :to="{ path: `/login` }">{{ $ml.get('login_login') }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import Vue from "vue";

export default {
  data() {
    return {
      form: {
        email: '',
      },
      errors: {},
      formLoading: false,
    }
  },
  computed: {
    submitEnabled() {
      return this.form.email.length >= 5
    }
  },
  methods: {
    login: function () {
      this.formLoading = true
      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'auth/reset', {user: this.form}).then(resp => {
        router.push('/login?r=t');
      }).catch(error => {
        this.errors = error.response.data.result
      }).finally(() => {
        this.formLoading = false
      })
    },
  }
}
</script>

<style scoped>
img {
  max-width: 300px;
}
</style>